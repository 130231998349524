<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content"></div>
  </div>
</template>
<script>
import { get_order_id } from "./service";
export default {
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  computed: {},
  components: {},
  watch: {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/anesthesia-result" || from.path === "/my-check-detail" || from.path === "/pre-order") {
        vm.$router.push('/')
      } else {
        vm.init()
      }
    });
  },
  created() {
  },
  destroyed() {},
  methods: {
    async init() {
      let id = this.$route.query.id
      if(!id) {
        this.$notify({
          type: 'danger',
          message: '请传入项目id',
        })
        return
      }
      let payload = {
        examine_ids: [id],
      };
      this.loading = true;
      try {
        const { data } = await get_order_id(payload);
        this.data = data;
        this.jump(data);
      } finally {
        this.loading = false;
      }
    },
    jump(data) {
      switch (data.jump_type) {
      case 1:
        this.$router.push({
          path: "/anesthesia-result",
        });
        break;
      case 3:
        this.$router.push({
          path: "/anesthesia-result",
          query: {
            order_id: data.order_id
          }
        });
        break;
      case 4:
        this.$router.push({
          path: "/my-check-detail",
          query: {
            order_id: data.order_id,
          }
        });
        break;
      default:
        this.$router.push({
          path: "pre-order",
          query: {
            order_id: data.order_id,
            anesthesia_estimate: data.anesthesia_estimate,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
